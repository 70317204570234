import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { fallbackLocale, dateTimeFormats } from '@/locales';
import messages from '@/locales/messages';

Vue.use(VueI18n);

const i18n = new VueI18n({
  fallbackLocale,
  dateTimeFormats,
  messages,
});

export default i18n;
