import localforage from 'localforage';

const storage = localforage.createInstance({
  driver: localforage.LOCALSTORAGE,
  name: 'live',
});

const Key = {
  LOCALE: 'locale',
};

export default class ApplicationStorage {
  static async setLocale(locale) {
    return storage.setItem(Key.LOCALE, locale);
  }

  static async getLocale() {
    return storage.getItem(Key.LOCALE);
  }

  static async clear() {
    return storage.clear();
  }
}
