import Vue from 'vue';
import VueGtag from 'vue-gtag';

import router from '@/router';

import { GA_MEASUREMENT_ID as id, ENABLED as enabled } from '@/config/gtag';

Vue.use(
  VueGtag,
  {
    enabled,
    config: { id },
  },
  router
);
