/* eslint-disable no-param-reassign */
import urlcat from 'urlcat';

import { createClient } from '../client';
import { API_BASE_URL } from '../config';
import { HEALTH_CHECK } from '../config/endpoints';

const baseURL = urlcat(API_BASE_URL, HEALTH_CHECK);
const client = createClient(baseURL);

export default class HealthCheck {
  static async get() {
    return client.get('', {
      transformRequest: [
        (data, headers) => {
          delete headers.Authorization;

          return data;
        },
      ],
    });
  }
}
