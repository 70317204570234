import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import { authCheckGuard } from '@lib/vue-playrix-auth/guards';

import routes from './routes';

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authCheckGuard);

export default router;
