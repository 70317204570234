import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '../config';

export default class AuthStorage {
  static saveTokens(authToken, refreshToken) {
    this.save(TOKEN_KEY, authToken);
    this.save(REFRESH_TOKEN_KEY, refreshToken);
  }

  static getToken() {
    return this.get(TOKEN_KEY);
  }

  static getRefreshToken() {
    return this.get(REFRESH_TOKEN_KEY);
  }

  static clear() {
    this.remove(TOKEN_KEY);
    this.remove(REFRESH_TOKEN_KEY);
  }

  static get(key) {
    return localStorage.getItem(key);
  }

  static save(key, value) {
    localStorage.setItem(key, value);
  }

  static remove(key) {
    localStorage.removeItem(key);
  }
}
