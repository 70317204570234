<template>
  <centered-layout>
    <div class="text-center">
      <h1 v-if="code" class="text-h1 mb-4">{{ code }}</h1>
      <p class="text-h5 font-weight-medium mb-2">{{ error }}</p>
      <p v-if="message" class="subtitle-1 font-weight-regular mb-0">{{ message }}</p>
    </div>
  </centered-layout>
</template>

<script>
import CenteredLayout from './CenteredLayout.vue';

export default {
  props: {
    code: {
      type: [String, Number],
    },
    error: {
      type: String,
      required: true,
    },
    message: {
      type: String,
    },
  },
  components: {
    CenteredLayout,
  },
};
</script>
