/* eslint-disable no-console */

function buildGetParams(params = {}) {
  return Object.entries(params)
    .filter(entry => !!entry[1])
    .flatMap(([param, value]) => {
      if (Array.isArray(value)) {
        return value.map(val => `${param}[]=${encodeURIComponent(val)}`);
      }

      return `${param}=${encodeURIComponent(value)}`;
    })
    .join('&');
}

function parseUrlHost(url) {
  return new URL(url).host;
}

function stripTrailingSlash(value) {
  if (!value.endsWith('/')) {
    return value;
  }

  return value.slice(0, -1);
}

function warn(message) {
  if (typeof console === 'undefined') {
    return;
  }

  console.warn(`[AuthService]: ${message}.`);
}

export { warn, buildGetParams, parseUrlHost, stripTrailingSlash };
