const parseBoolVariable = function(value) {
  if (typeof value !== 'string') {
    return Boolean(value);
  }

  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    default:
      return false;
  }
};

const parseNumberVariable = function(value) {
  const number = Number(value);

  if (Number.isNaN(number)) {
    return 0;
  }

  return number;
};

const parseArrayVariable = function(value, separator = '') {
  if (typeof value !== 'string') {
    return [];
  }

  return value.split(separator);
};

export { parseBoolVariable, parseNumberVariable, parseArrayVariable };
