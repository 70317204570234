import Vue from 'vue';

import { warn } from './helpers';

const EventHub = new Vue();

export default class VueEventHub {
  static install(VueInstance) {
    if (process.env.NODE_ENV !== 'production' && this.installed) {
      warn('Already installed');

      return;
    }

    this.installed = true;

    Object.defineProperty(VueInstance.prototype, '$eventHub', {
      configurable: false,
      enumerable: false,
      get() {
        return EventHub;
      },
    });
  }
}

export { EventHub };
