const matchMedia = function(query) {
  return window.matchMedia && window.matchMedia(query);
};

const matchDarkScheme = function() {
  return matchMedia('(prefers-color-scheme: dark)');
};

const detectDarkMode = function() {
  const media = matchDarkScheme();

  return media && media.matches;
};

export { matchMedia, matchDarkScheme, detectDarkMode };
