const {
  BASE_URL,
  VUE_APP_AUTH_URL: AUTH_URL,
  VUE_APP_AUTH_SERVICE_ID: SERVICE_ID,
  VUE_APP_AUTH_SCOPE: SCOPE,
  VUE_APP_AUTH_PERMISSIONS_SCOPE: PERMISSIONS_SCOPE,
  VUE_APP_AUTH_DUMMY: dummy,
} = process.env;

const DUMMY = dummy && dummy === 'true';

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh_token';

const SECONDS_BEFORE_EXPIRE = 30 * 60;

export {
  BASE_URL,
  AUTH_URL,
  SERVICE_ID,
  SCOPE,
  PERMISSIONS_SCOPE,
  DUMMY,
  TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  SECONDS_BEFORE_EXPIRE,
};
