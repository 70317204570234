const locales = new Set(['ru', 'en']);

const fallbackLocale = 'ru';

const languages = {
  ru: 'Rus',
  en: 'Eng',
};

const dateTimeFormats = {
  ru: {
    long: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
  },
};

export { locales, fallbackLocale, languages, dateTimeFormats };
