/* eslint-disable no-console */

import Vue from 'vue';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';

import meta from '../../package.json';

import { DSN as dsn, ENVS } from '../config/sentry';

import { SERVER_ENV as environment } from '../config';

const enabled = ENVS.includes(environment);

if (!enabled && process.env.NODE_ENV !== 'production') {
  console.warn(`[Sentry]: won't capture errors in ${environment} environment.`);
}

const { name, version } = meta;

Sentry.init({
  Vue,
  dsn,
  environment,
  enabled,
  logErrors: true,
  release: `${name}@${version}`,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true,
  },
  ignoreErrors: [
    // Errors from browser extensions
    'Non-Error promise rejection captured',

    // Safari PiP mode specific errors
    'NotSupportedError: The video element does not support the Picture-in-Picture mode',
    'InvalidStateError: The video element does not have a video track',

    // Player errors that must be handled by Video.js
    'AbortError: The operation was aborted',
    'AbortError: The play() request was interrupted',
    'DOMException: The play() request was interrupted',
    "Cannot set properties of undefined (setting 'playbackRate')",

    // Unknown environment with limited apis (parser, autotest, etc.)
    'window.requestAnimationFrame is not a function',
  ],
});
