export default {
  ru: {
    language: 'Русский',
    common: {
      login: 'Войти',
      logout: 'Выход',
    },
    app: {
      title: 'Загрузка...',
    },
    views: {
      auth: {
        title: 'Необходимо авторизоваться',
        processing: 'Авторизуемся',
        error: 'Ошибка авторизации',
        login: '@:common.login',
      },
      errors: {
        notFound: 'Страница не найдена',
        accessDenied: 'Нет доступа',
      },
      main: {
        error: {
          title: 'Сервис недоступен :(',
          message: 'Попробуйте снова или обратитесь в сервисную службу',
          server: 'Сервер недоступен',
        },
      },
      stream: {
        title: 'Трансляция',
        tooltip: {
          reactions: 'Реакции доступны в прямом эфире',
        },
        error: {
          settings: 'Ошибка получения настроек',
        },
      },
      record: {
        title: 'Запись трансляции',
        error: {
          translation: 'Не удалось загрузить данные записи',
          tracks: 'Не удалось загрузить отметки эпизодов',
        },
      },
    },
    components: {
      poster: {
        source: 'Трансляция недоступна на выбранном языке',
        start: {
          date: 'Начало трансляции: {date}',
          scheduled: 'Трансляция запланирована',
        },
        finished: {
          message: 'Запись трансляции скоро появится в {link}',
          link: 'базе знаний',
        },
        uploaded: {
          message: 'Запись последней трансляции выложена в {link}',
          link: 'базе знаний',
        },
      },
      player: {
        error: {
          credentials: 'Ошибка получения данных авторизации для медиа',
        },
      },
    },
  },
  en: {
    language: 'English',
    common: {
      login: 'Login',
      logout: 'Logout',
    },
    app: {
      title: 'Loading...',
    },
    views: {
      auth: {
        title: 'Login required',
        processing: 'Authorizing',
        error: 'Authorisation error',
        login: '@:common.login',
      },
      errors: {
        notFound: 'Not found',
        accessDenied: 'Access denied',
      },
      main: {
        error: {
          title: 'Service is unavailable :(',
          message: 'Try again or contact service desk',
          server: 'Server is not available',
        },
      },
      stream: {
        title: 'Stream',
        tooltip: {
          reactions: 'Reactions are available live',
        },
        error: {
          settings: 'Failed to fetch settings',
        },
      },
      record: {
        title: 'Stream record',
        error: {
          translation: 'Failed to fetch record data',
          tracks: 'Failed to load video chapters',
        },
      },
    },
    components: {
      poster: {
        source: 'The broadcast is not available for this language',
        start: {
          date: 'The broadcast starts on: {date}',
          scheduled: 'The broadcast is scheduled',
        },
        finished: {
          message: 'The broadcast recording will soon appear in the {link}',
          link: 'knowledge base',
        },
        uploaded: {
          message: 'The broadcast recording is available in the {link}',
          link: 'knowledge base',
        },
      },
      player: {
        error: {
          credentials: 'Failed to fetch auth credentials for media',
        },
      },
    },
  },
};
