import urlcat from 'urlcat';

import AuthService from '../service';
import { stripTrailingSlash } from '../helpers';
import { BASE_URL } from '../config';

export default {
  methods: {
    proceedAuth() {
      const redirect = this.buildRedirectUrl();

      AuthService.proceedToSignIn(redirect);
    },
    buildRedirectUrl() {
      const { origin } = window.location;
      const { from } = this.$route.params;

      let path = stripTrailingSlash(BASE_URL);

      if (from) {
        const { fullPath } = from;

        path += fullPath;
      }

      return urlcat(origin, path);
    },
  },
}
