import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib';

import ru from 'vuetify/es5/locale/ru';
import colors from 'vuetify/lib/util/colors';

import { DARK_THEME, DARK_THEME_AUTO } from '@/config/features';
import { detectDarkMode } from '@/helpers/media';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

const dark = DARK_THEME || (DARK_THEME_AUTO && detectDarkMode());

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    dark,
    options: { customProperties: true },
    themes: {
      light: {
        primary: colors.blue.darken3,
        warning: colors.orange.lighten3,
        lines: colors.grey.lighten3,
      },
      dark: {
        // primary: colors.blue.darken3,
        primary: '#28d223',
        info: colors.blue.darken1,
        error: colors.red.darken1,
        success: colors.green.darken1,
        warning: colors.orange.darken3,
        lines: colors.grey.darken3,
      },
    },
  },
});
