<template>
  <centered-layout>
    <div class="text-center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </div>
  </centered-layout>
</template>

<script>
import CenteredLayout from './CenteredLayout.vue';

export default {
  components: { CenteredLayout },
};
</script>
