<template>
  <div class="header-locale">
    <template v-for="(locale, index) in locales">
      <button
        :key="locale"
        class="header-locale__btn"
        :class="activeLocaleClass(locale)"
        :disabled="activeLocale(locale)"
        @click="handleLocaleClick(locale)"
      >
        {{ languages[locale] }}
      </button>
      <div
        :key="`divider-${locale}`"
        v-if="index < locales.size - 1"
        class="header-locale__divider"
      ></div>
    </template>
  </div>
</template>

<script>
import LocaleMixin from '@/mixins/locale';

export default {
  methods: {
    activeLocale(locale) {
      return this.locale === locale;
    },
    activeLocaleClass(locale) {
      return {
        'header-locale__btn--active': this.activeLocale(locale),
      };
    },
    handleLocaleClick(locale) {
      this.setLocale(locale).then(() => window.location.reload());
    },
  },
  mixins: [LocaleMixin],
};
</script>
