import axios from 'axios';

import { TOKEN, REFRESH } from './config/endpoints';
import { AUTH_URL } from './config';

const client = axios.create({
  baseURL: AUTH_URL,
  validateStatus(status) {
    return status >= 200 && status < 400;
  },
});

client.interceptors.response.use(
  response => response,
  error => {
    const { response } = error;

    if (!response) {
      throw error;
    }

    const { data } = response;

    if (!data || !data.error) {
      throw error;
    }

    throw new Error(data.error);
  }
);

export default class AuthAPI {
  static token(serviceId, code) {
    const data = {
      code,
      service_id: serviceId,
    };

    return client.post(TOKEN, data).then(response => response.data);
  }

  static refresh(serviceId, authToken, refreshToken) {
    const data = {
      service_id: serviceId,
      auth_token: authToken,
      refresh_token: refreshToken,
    };

    return client.post(REFRESH, data).then(response => response.data);
  }
}
