/* eslint-disable no-param-reassign */

import AuthService from '@lib/vue-playrix-auth/service';
import { NOT_AUTHORIZED } from '@/constants/events';

import EventHub from '@/plugins/vue-event-hub';
import i18n from '@/plugins/vue-i18n';

const defaultErrorText = 'Unknown error';

const buildException = function(response) {
  const { data, status, statusText } = response;

  const exception = new Error(statusText || defaultErrorText);

  exception.status = status;
  exception.response = response;

  if (!data) {
    return exception;
  }

  const { detail, error, code } = data;

  const message = detail || error;

  if (typeof message === 'string') {
    exception.message = message;
  }

  if (typeof code !== 'undefined') {
    exception.code = code;
  }

  return exception;
};

const beforeRequest = function(config) {
  const { headers } = config;

  config.headers = {
    Authorization: AuthService.getToken(),
    'Accept-Language': i18n.locale,
    ...headers,
  };

  return config;
};

const errorRequest = function(error) {
  return Promise.reject(error);
};

const successResponse = function(response) {
  return response;
};

const errorResponse = function(error) {
  const { response } = error;

  if (!response) {
    throw error;
  }

  const { status } = response;

  if (status === 401) {
    EventHub.$emit(NOT_AUTHORIZED);

    return Promise.reject(error);
  }

  throw buildException(response);
};

export { beforeRequest, errorRequest, successResponse, errorResponse };
