import AuthService from './service';

const tokenRefreshGuard = function(to, from, next) {
  if (!AuthService.isAuthorized()) {
    return next();
  }

  AuthService.refreshToken()
    .then(() => next())
    .catch(() => {
      AuthService.deauthorize().finally(() => next({ name: 'auth' }));
    });
};

const authCheckGuard = function(to, from, next) {
  const authorized = AuthService.isAuthorized();

  if (to.matched.some(record => record.meta.requiresAuth) && !authorized) {
    return next({ name: 'auth', params: { from: to } });
  }

  if (to.matched.some(record => record.meta.requiresAnon) && authorized) {
    return next({ name: 'main' });
  }

  next();
};

export { tokenRefreshGuard, authCheckGuard };
