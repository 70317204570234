import AuthService from '../service';
import { parseUrlHost } from '../helpers';

export default {
  methods: {
    async processAuth() {
      const { code, redirect_uri: redirect } = this.$route.query;

      await this.processAuthCode(code);
      await this.processRedirect(redirect);
    },
    async processAuthCode(code) {
      if (!code) {
        throw new Error('Code is not specified');
      }

      return AuthService.authorize(code);
    },
    async processRedirect(redirect) {
      if (!this.validateRedirect(redirect)) {
        return this.$router.push({ name: 'main' });
      }

      window.location.href = redirect;
    },
    validateRedirect(redirect) {
      return !!redirect && parseUrlHost(redirect) === window.location.host;
    },
  },
}
