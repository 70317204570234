import { locales, languages } from '@/locales';
import { determineLocale } from '@/locales/helpers';
import ApplicationStorage from '@/storage/application';

export default {
  computed: {
    locales: () => locales,
    languages: () => languages,
    locale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    async setLocale(code) {
      let locale = code;

      if (!locale) {
        locale = await ApplicationStorage.getLocale()
          .then(saved => saved || determineLocale())
          .catch(() => {});
      }

      if (!locale) {
        return;
      }

      if (!this.locales.has(locale)) {
        return;
      }

      this.$root.$i18n.locale = locale;

      return ApplicationStorage.setLocale(locale);
    },
    trackLocaleEvent() {
      this.$gtag.event('set_locale', {
        locale: this.locale,
      });
    },
  },
};
