import AuthService from '../service';

export default {
  methods: {
    async refreshToken() {
      return AuthService.refreshToken()
    },
    async deauthorize() {
      return AuthService.deauthorize();
    },
  }
}
