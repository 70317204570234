import urlcat from 'urlcat';

import { warn, buildGetParams } from '../helpers';

import AuthCredentials from './credentials';

import {
  AUTH_URL,
  SERVICE_ID,
  SCOPE,
  PERMISSIONS_SCOPE,
  DUMMY,
  SECONDS_BEFORE_EXPIRE,
} from '../config';

import { REDIRECT } from '../config/endpoints';

export default class AuthService {
  static proceedToSignIn(redirectURL) {
    const url = urlcat(AUTH_URL, REDIRECT);

    const params = buildGetParams({
      service_id: SERVICE_ID,
      scope: SCOPE,
      redirect: redirectURL,
    });

    window.location.href = `${url}?${params}`;
  }

  static async authorize(code) {
    try {
      await AuthCredentials.obtain(code);
    } catch (error) {
      throw new Error(`Auth error: ${error.message}`);
    }
  }

  static async refreshToken() {
    if (DUMMY) {
      warn('refreshToken() is in dummy mode');

      return;
    }

    const payload = AuthCredentials.getTokenPayload();

    const { expire_time: expires } = payload;

    const current = new Date().getTime();

    if (expires - current / 1000 > SECONDS_BEFORE_EXPIRE) {
      return;
    }

    await AuthCredentials.refresh();
  }

  static getToken() {
    return AuthCredentials.getToken();
  }

  static getTokenPermissions() {
    if (DUMMY) {
      warn('getTokenPermissions() is in dummy mode');

      return { is_admin: true, permissions: [] };
    }

    const payload = AuthCredentials.getTokenPayload();

    if (!payload) {
      throw new Error('No payload');
    }

    const { scopes = {} } = payload;

    return scopes[PERMISSIONS_SCOPE];
  }

  static isAuthorized() {
    if (DUMMY) {
      warn('isAuthorized() is in dummy mode');

      return true;
    }

    return !!this.getToken();
  }

  static async deauthorize() {
    AuthCredentials.clear();
  }
}
