import AuthAPI from '../api';
import AuthStorage from './storage';

import { SERVICE_ID } from '../config';

export default class AuthCredentials {
  static async obtain(code) {
    const creds = await AuthAPI.token(SERVICE_ID, code);

    this.saveCredentials(creds);
  }

  static async refresh() {
    const creds = await AuthAPI.refresh(
      SERVICE_ID,
      AuthStorage.getToken(),
      AuthStorage.getRefreshToken()
    );

    if (!creds) {
      throw new Error('API returned no credentials');
    }

    this.saveCredentials(creds);
  }

  static saveCredentials(creds) {
    const { token: authToken, refresh_token: refreshToken } = creds;

    if (!authToken || !refreshToken) {
      throw new Error('Empty tokens');
    }

    AuthStorage.saveTokens(authToken, refreshToken);
  }

  static getToken() {
    return AuthStorage.getToken();
  }

  static getTokenPayload() {
    const decoded = this.decodeToken();

    return decoded.payload;
  }

  static decodeToken() {
    const token = AuthStorage.getToken();

    if (!token) {
      throw new Error('Token is blank');
    }

    try {
      const [header, payload] = token.split('.', 2).map(part => JSON.parse(atob(part)));

      return { header, payload };
    } catch (error) {
      throw new Error(`Failed to decode token: ${error.message}`);
    }
  }

  static clear() {
    AuthStorage.clear();
  }
}
