import Main from '@/views/Main.vue';

const Stream = () => import('@/views/main/Stream.vue');
const Record = () => import('@/views/main/Record.vue');

const Auth = () => import('@/views/Auth.vue');
const AuthCallback = () => import('@/views/AuthCallback.vue');

const NotFound = () => import('@/views/error/NotFound.vue');
const AccessDenied = () => import('@/views/error/AccessDenied.vue');

export default [
  {
    path: '/',
    name: 'main',
    component: Main,
    redirect: { name: 'stream' },
    meta: { requiresAuth: true },
    children: [
      {
        path: 'stream',
        name: 'stream',
        component: Stream,
      },
      {
        path: 'record/:id',
        name: 'record',
        component: Record,
        props: ({ params, query }) => ({
          id: Number(params.id),
          time: query.t && Number(query.t),
        }),
      },
      {
        path: '404',
        name: 'notFound',
        component: NotFound,
      },
      {
        path: '403',
        name: 'accessDenied',
        component: AccessDenied,
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: { requiresAnon: true },
  },
  {
    path: '/auth/callback',
    name: 'authCallback',
    component: AuthCallback,
    meta: { requiresAnon: true },
  },
  {
    path: '*',
    redirect: { name: 'notFound' },
  },
];
