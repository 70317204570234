import Vue from 'vue';

import './plugins';

import App from './App.vue';
import router from './router';

import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';

import './assets/stylesheets/main.scss';

Vue.config.productionTip = process.env.NODE_ENV === 'production';

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
