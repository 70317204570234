<template>
  <router-view></router-view>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('app.title'),
      titleTemplate: '%s | Playrix Live',
    };
  },
};
</script>
