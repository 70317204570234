<template>
  <v-app>
    <template v-if="initialized">
      <the-header></the-header>

      <v-main>
        <router-view></router-view>
      </v-main>
    </template>

    <template v-else-if="loading">
      <loading-layout></loading-layout>
    </template>

    <template v-else>
      <error-layout
        :error="$t('views.main.error.title')"
        :message="$t('views.main.error.message')"
      ></error-layout>
    </template>
  </v-app>
</template>

<script>
import { AuthMixin } from '@lib/vue-playrix-auth/mixins';

import LoadingLayout from '@/views/layouts/LoadingLayout.vue';
import ErrorLayout from '@/views/layouts/ErrorLayout.vue';
import TheHeader from '@/components/common/header/TheHeader.vue';

import LocaleMixin from '@/mixins/locale';

import HealthCheck from '@/api/http/health-check';

import { NOT_AUTHORIZED } from '@/constants/events';

export default {
  data() {
    return {
      initialized: false,
      loading: false,
    };
  },
  created() {
    this.setupDOMEvents();
    this.setupHubEvents();
  },
  beforeMount() {
    this.loading = true;

    this.initialize()
      .then(() => {
        this.initialized = true;
      })
      .catch(error => error)
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {
    this.setLocale();

    requestAnimationFrame(() => {
      this.trackLocaleEvent();
    });
  },
  destroyed() {
    this.removeDOMEvents();
    this.removeHubEvents();
  },
  methods: {
    setupDOMEvents() {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    removeDOMEvents() {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    setupHubEvents() {
      this.$eventHub.$once(NOT_AUTHORIZED, this.handleNotAuthorized);
    },
    removeHubEvents() {
      this.$eventHub.$off(NOT_AUTHORIZED, this.handleNotAuthorized);
    },
    async initialize() {
      await this.serverHealthCheck();
      await this.refreshAuthToken();
    },
    async serverHealthCheck() {
      return HealthCheck.get().catch(error => {
        this.$toast.error(this.$t('views.main.error.server'));

        return Promise.reject(error);
      });
    },
    async refreshAuthToken() {
      return this.refreshToken().catch(error => {
        this.handleNotAuthorized();

        throw error;
      });
    },
    handleNotAuthorized() {
      this.deauthorize().then(() => this.$router.push({ name: 'auth' }));
    },
    handleVisibilityChange() {
      if (document.hidden || !this.initialized) {
        return;
      }

      this.refreshAuthToken().catch(error => {
        this.$toast.error(error.message);
      });
    },
  },
  components: {
    LoadingLayout,
    ErrorLayout,
    TheHeader,
  },
  mixins: [AuthMixin, LocaleMixin],
};
</script>
