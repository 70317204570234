import intersection from 'lodash/intersection';

import { locales } from '.';

const getUserLocale = function() {
  const { language } = window.navigator;

  if (!language) {
    return;
  }

  return language.split('-')[0];
};

const getUserPreferredLocale = function() {
  const { languages } = window.navigator;

  const common = intersection(languages, locales);

  return common[0];
};

const determineLocale = function() {
  return getUserLocale() || getUserPreferredLocale();
};

export { getUserLocale, getUserPreferredLocale, determineLocale };
