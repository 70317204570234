import Vue from 'vue';
import VuetifyToast from 'vuetify-toast-snackbar';

import vuetify from './vuetify';

Vue.use(VuetifyToast, {
  vuetify,
  timeout: 7000,
  queueable: true,
  showClose: true,
  closeIcon: 'close',
});
