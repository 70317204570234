import { parseBoolVariable } from '@/helpers/envs';

const {
  VUE_APP_FEATURE_DARK_THEME: darkTheme,
  VUE_APP_FEATURE_DARK_THEME_AUTO: darkThemeAuto,
  VUE_APP_FEATURE_STATISTIC: statistic,
} = process.env;

const DARK_THEME = parseBoolVariable(darkTheme);
const DARK_THEME_AUTO = parseBoolVariable(darkThemeAuto);
const STATISTIC = parseBoolVariable(statistic);

export { DARK_THEME, DARK_THEME_AUTO, STATISTIC };
