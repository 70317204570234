<template>
  <v-app-bar color="#121212" app dense flat clipped-left>
    <router-link class="text-decoration-none" :to="{ name: 'main' }">
      <the-header-logo />
    </router-link>

    <v-spacer />

    <the-header-locale class="mr-5" text />

    <v-btn depressed small @click="handleLogout">{{ $t('common.logout') }}</v-btn>
  </v-app-bar>
</template>

<script>
import TheHeaderLogo from '@/components/common/header/TheHeaderLogo.vue';
import TheHeaderLocale from '@/components/common/header/TheHeaderLocale.vue';

import { NOT_AUTHORIZED } from '@/constants/events';

export default {
  methods: {
    handleLogout() {
      this.$eventHub.$emit(NOT_AUTHORIZED);
    },
  },
  components: { TheHeaderLogo, TheHeaderLocale },
};
</script>
